import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Colin = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio colin__text backdrop__bio">



            <picture>
                <source className="artist__picture"
                    srcSet="/content/pictures/artists-portrait/webp/Colin-Andrew-Sheffield_FLESHTV.webp" type="image/webp"
                    alt="Colin Andrew Sheffield FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Colin-Andrew-Sheffield_FLESHTV.jpg"
                    loading="lazy" alt="Colin Andrew Sheffield FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('colin-bio')}
                    <br />

                    <a href="http://elevatorbath.com/" target="_blank">elevatorbath.com/</a><br />

                </p>

                <h1>Colin Andrew Sheffield (US)</h1>
            </div>

            <ButtonClose />

        </div>

    )
}

export default Colin;