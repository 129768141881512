import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/style.scss';

import ThreeCanvas from './components/ThreeCanvas';
import ThreeCanvasOptimized from './components/ThreeCanvasOptimized';

import LanguageSwitcher from './components/LanguageSwitcher';

import './js/i18n';
import LoaderFake from './components/Loader';

import Loader from './components/Loader';
import AboutSection from './components/AboutSection';
import AlbumSection from './components/AlbumSection';
import ButtonBack from './components/ButtonBack';
import MusicBackground from './components/MusicBackground';
import Menu from './components/Menu';
import GrainBackground from './components/GrainBackground';



function App() {

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );
  })

  return (

    <Router>
      {/*   <Loader /> */}
      {/*  <LoaderFake /> */}
      {/*  <GrainBackground /> */}

      <MusicBackground />
      <LanguageSwitcher />
      <ThreeCanvasOptimized />

      <ButtonBack />

      {/*  <ThreeCanvas />  */}

      <Menu />


      <Routes>
        <Route path="/about" element={<AboutSection />} />
        <Route path="/album" element={<AlbumSection />} />
      </Routes>


    </Router>

  );
}

export default App;
