import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Dylan = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio dylan__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Dylan+Alfi_FLESHTV.webp"
                    type="image/webp" alt="Dylan Kerr and Knochengesicht FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Dylan+Alfi_FLESHTV.jpg" loading="lazy"
                    alt="Dylan Kerr and Knochengesicht FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('dylan-bio')}
                    <br />

                    <a href="https://baptistgoth.bandcamp.com/releases" target="_blank">baptistgoth.bandcamp.com/</a><br />
                    <a href="https://soundcloud.com/dylan-kerr-6" target="_blank">soundcloud.com/dylan-kerr-6/</a> <br /> <br />



                    {t('knochengesicht-bio')}
                    <br />

                    <a href="https://bonyface.net/" target="_blank">bonyface.net/</a><br />
                    <a href="https://www.straysignals.net/" target="_blank">straysignals.net/</a><br />
                </p>

                <h1>Dylan Kerr (IE) + Knochengesicht (UK)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Dylan;