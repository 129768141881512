import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Loreles = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio loreles__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Loreles_FLESHTV.webp"
                    type="image/webp" alt="Loreles FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Loreles_FLESHTV.jpg" loading="lazy"
                    alt="Loreles FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('loreles-bio')}
                    <br />


                </p>

                <h1>Loreles (FR)</h1>


            </div>

            <ButtonClose />

        </div>

    )
}

export default Loreles;