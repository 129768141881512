// GrainShaderMaterial.js
import { ShaderMaterial, UniformsUtils } from 'three';
import { extend } from '@react-three/fiber';
import { useFrame } from '@react-three/fiber';
import React, { useRef, useEffect } from 'react';

const GrainShader = {
    uniforms: {
        'tDiffuse': { value: null },
        'amount': { value: 0.5 }, // Adjust the grain intensity
        'time': { value: 0.0 }
    },
    vertexShader: `
        varying vec2 vUv;
        
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float amount;
        uniform float time;
        
        varying vec2 vUv;

        float rand(vec2 co) {
            return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453 + time);
        }

        void main() {
            vec4 color = texture2D(tDiffuse, vUv);
            vec2 uvRandom = vUv;
            uvRandom.y *= rand(vec2(uvRandom.y, time));
            color.rgb += rand(uvRandom) * amount;
            gl_FragColor = color;
        }
    `
};

class GrainShaderMaterialImpl extends ShaderMaterial {
    constructor() {
        super({
            uniforms: UniformsUtils.clone(GrainShader.uniforms),
            vertexShader: GrainShader.vertexShader,
            fragmentShader: GrainShader.fragmentShader
        });
    }

    get time() {
        return this.uniforms.time.value;
    }

    set time(value) {
        this.uniforms.time.value = value;
    }
}

extend({ GrainShaderMaterialImpl });

const GrainShaderMaterial = () => {
    const materialRef = useRef();
    useFrame((state, delta) => {
        if (materialRef.current) {
            materialRef.current.time += delta;
        }
    });

    return <grainShaderMaterialImpl ref={materialRef} attach="material" />;
};

export default GrainShaderMaterial;