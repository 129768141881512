import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Cedrik = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio cedrik__text backdrop__bio">



            <div className="container__picture">
                <picture>
                    <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Cedrik-Fermont_FLESHTV.webp"
                        type="image/webp" alt="Cedrik Fermont FLESHTV" />
                    <img className="artist__picture" src="/content/pictures/artists-portrait/Cedrik-Fermont_FLESHTV.jpg" loading="lazy"
                        alt="Cedrik Fermont FLESHTV" width="1000" height="1000" />
                </picture>
                <div className="credit__photo">© Magda Bondos</div>
            </div>


            <div>
                <p className="text">
                    {t('cedrik-bio')}
                    <br />

                    <a href="http://syrphe.com/" target="_blank">syrphe.com/</a><br />
                    <a href="https://syrphe.bandcamp.com/" target="_blank">syrphe.bandcamp.com/</a><br />

                </p>

                <h1>Cedrik Fermont (BE/CD)</h1>
            </div>

            <ButtonClose />

        </div>

    )
}

export default Cedrik;