import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const THAA = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio thaa__text backdrop__bio">

            <picture>
                <source className="artist__picture"
                    srcSet="/content/pictures/artists-portrait/webp/THAA(Andrew-Anderson+Thor-Harris)_FLESHTV.webp"
                    type="image/webp" alt="THAA Andrew Anderson Thor Harris FLESHTV" />
                <img className="artist__picture"
                    src="/content/pictures/artists-portrait/THAA(Andrew-Anderson+Thor-Harris)_FLESHTV.jpg" loading="lazy"
                    alt="THAA Andrew Anderson Thor Harris FLESHTV" width="1000" height="1000" />
            </picture>


            <div>
                <p className="text">
                    {t('andrew-bio')}
                    <br />

                    <a href="https://andrewjamesanderson.bandcamp.com/" target="_blank">andrewjamesanderson.bandcamp.com/</a><br />
                    <a href="https://thaa.bandcamp.com/releases" target="_blank">thaa.bandcamp.com/</a>

                    <br /><br />

                    {t('thor-bio')}<br />

                    <a href="http://www.thorharris.org/" target="_blank">thorharris.org/</a><br />
                    <a href="https://thorharris.bandcamp.com/music/" target="_blank">thorharris.bandcamp.com/music/</a><br />
                    <a href="https://thaa.bandcamp.com/releases" target="_blank">thaa.bandcamp.com/</a><br />
                </p>

                <h1>Andrew Anderson + Thor Harris (US)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default THAA;