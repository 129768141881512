import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const OxhySusu = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio oxhy__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Oxhy_FLESHTV.webp"
                    type="image/webp" alt="Oxhy Susu Laroche FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Oxhy_FLESHTV.jpg" loading="lazy"
                    alt="Oxhy Susu Laroche FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('oxhy-bio')}
                    <br />

                    <a href="https://oxhy.xyz/" target="_blank">oxhy.xyz/</a><br />
                    <a href="https://oxhy.bandcamp.com/" target="_blank">oxhy.bandcamp.com/</a>

                    <br /><br />

                    {t('susu-bio')}<br />

                    <a href="http://www.susularoche.com/" target="_blank">susularoche.com/</a>
                </p>

                <h1>Oxhy Phillips + Susu Laroche (UK)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default OxhySusu;