import React, { useEffect } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import '../js/i18n';


const MusicBackground = () => {




    const { t } = useTranslation();

    useEffect(() => {
        const musicPlayer = document.getElementById('musicPlayer');
        const buttonTopPlayPause = document.querySelector('.button__player');

        const spanButton = document.querySelector('.button__player span');


        const playMusicAndShowButton = () => {
            musicPlayer.play();
            buttonTopPlayPause.style.display = 'block';
            document.body.removeEventListener('click', playMusicAndShowButton);
        };

        document.body.addEventListener('click', playMusicAndShowButton);

        const togglePlayPause = (event) => {
            event.stopPropagation();

            if (spanButton.innerHTML === 'pause') {
                musicPlayer.pause();
                spanButton.innerHTML = 'play';
            } else if (spanButton.innerHTML === 'play') {
                musicPlayer.play();
                spanButton.innerHTML = 'pause';
            }
        };

        spanButton.addEventListener('click', togglePlayPause);

        // Cleanup event listeners on component unmount
        return () => {
            document.body.removeEventListener('click', playMusicAndShowButton);
            spanButton.removeEventListener('click', togglePlayPause);
        };
    }, []);

    return (

        <div>

            <div className="button__player">
                {/*  <span className="play"><Trans i18nKey="pause-button"/></span> */}
                <span className="play">pause</span>

            </div>


            <div id="audioPlayerContainer">
                <audio loop id="musicPlayer" src="/content/player/FelicityMangan-MemoryLapse.mp3"></audio>
                <button id="playButton">read</button>
                <button id="pauseButton">pause</button>
            </div>

        </div>

    )
}

export default MusicBackground;