import React, { useEffect } from 'react';


const SpeechAlbumEN = () => {

    useEffect(() => {

        const textAlbumEn = `FLESH TV ENCOURAGES A CONCEPTUAL AND SENSITIVE EXAMINATION OF SENTIENT LIFE, THROUGH THE LENS OF ART AND RESEARCH. WE WANT IT TO BE A SPACE FOR DIALOGUE BETWEEN SEVERAL DISCIPLINES AND MEDIA SUCH AS SOUND ART, EXPERIMENTAL & ELECTRONIC MUSIC, AVANT-GARDE, ELECTRO-ACOUSTIC, FIELD RECORDING, NOISE, AND OTHER SUB-GENRES THAT RELATE TO THIS DOMAIN. IT WILL ALSO HOST AUDIO-VISUAL EXPERIENCES, HYBRID, MIXED MEDIA FORMS, AND WRITTEN CONTENT LIKE ESSAYS, ARTICLES, AND TEXTS THAT ENHANCE UNDERSTANDING OF OUR AREAS OF SPECIALIZATION.

Flesh TV's first project pursues the use of sound art as a political tool to bring awareness to animal exploitation and to join the debate around animal rights through an innovative, creative format. This compilation features more than 20 artists from all over the globe concerned with this cause, each of whom made a unique contribution by submitting an exclusive track as well as comments reflecting their take on animal rights.

What makes this release special is that it encompasses a wide spectrum of hybrid, experimental sound material while conveying a powerful, collective message. The book part of this joint creation is a strong manifesto, in which the artists share their very personal and intimate vision of the animal condition today. They are offering their opinions, in order to feed a more globally political reflection on the animal rights movement, as well as, exploring their role as artists to empower a worldwide call for compassion and justice for all.

In times when discrimination and oppression are fought on the grandest scale (gender, ethnicity, class, sexual orientation, physical ability) it is both surprising and sad to notice how often this dynamic fails to include animal exploitation in its scope. The convergence of social struggles (sexism, racism, homophobia, and so forth) turns its back on speciesism, unable to see that these issues all arise from the same source. Our potential to improve depends on our capacity to see the bigger picture and to reflect on our share of violence and responsibility in systems of domination and brutality.

Last but not least, this compilation is about supporting organizations and activists with donations¹, in addition to providing these local, specific, and little-known initiatives with exposure. By inviting each artist to choose an earth or animal rights group or activist, Flesh TV intends to draw together a tribe of key players involved in framing a future where all lives are valued and respected, now and in its future projects.

Flesh TV supports all forms of activism and brings together people with their varying means of operation from legislative pressure to openly militant approaches. To give our utopias the power and reach they need to become embodied realities, actions must be executed simultaneously, on all fronts. Now more than ever before it's vital to shed light on all the projects that are actively working to fill an institutional void on these questions. These endeavors are fiercely standing as glimmers of hope in the face of political inaction..

Establishing a network of artists, researchers, collectives, activists, and a solid audience around Flesh TV is essential for our wish to nurture a new community. If you would like to contribute to the collaborative dynamic we are striving to create, please reach out to us.`


        const playElEn = document.getElementById('play-album-en');
        const pauseElEn = document.getElementById('pause-album-en');
        //const stopEl = document.getElementById('stop-fr');

        // add UI event handlers
        playElEn.addEventListener('click', playAboutEn);
        pauseElEn.addEventListener('click', stop);
        //stopEl.addEventListener('click', stop);

        function playAboutEn() {

            const utterance = new SpeechSynthesisUtterance(textAlbumEn);

            utterance.lang = 'en-GB';

            utterance.addEventListener('start', handleStart);
            utterance.addEventListener('pause', handlePause);
            utterance.addEventListener('resume', handleResume);
            utterance.addEventListener('end', handleEnd);
            utterance.addEventListener('boundary', handleBoundary);
            window.speechSynthesis.speak(utterance);
        }


        function pauseAboutEn() {
            window.speechSynthesis.pause();
        }

        function stop() {
            window.speechSynthesis.cancel();
            handleEnd();
        }
        function handleStart() {
            playElEn.disabled = true;
            pauseElEn.disabled = false;
            //stopEl.disabled = false;
        }

        function handlePause() {
            playElEn.disabled = false;
            pauseElEn.disabled = true;
            //stopEl.disabled = false;
        }

        function handleResume() {
            playElEn.disabled = true;
            pauseElEn.disabled = false;

        }

        function handleEnd() {
            playElEn.disabled = false;
            pauseElEn.disabled = true;

        }

        function handleBoundary(event) {
            if (event.name === 'sentence') {
                // we only care about word boundaries
                return;
            }

            const wordStart = event.charIndex;

            let wordLength = event.charLength;
            if (wordLength === undefined) {
                // Safari doesn't provide charLength, so fall back to a regex to find the current word and its length (probably misses some edge cases, but good enough for this demo)
                const match = textAlbumEn.substring(wordStart).match(/^[a-z\d']*/i);
                wordLength = match[0].length;
            }

            // wrap word in <mark> tag
            const wordEnd = wordStart + wordLength;
            const word = textAlbumEn.substring(wordStart, wordEnd);
            const markedText = textAlbumEn.substring(0, wordStart) + '<mark>' + word + '</mark>' + textAlbumEn.substring(wordEnd);
            //textEl.innerHTML = markedText;
        }



    })

    return (
        <div className="speech-buttons">
            <button id="play-album-en">read</button>
            <button id="pause-album-en" disabled>stop</button>
        </div>

    )

}

export default SpeechAlbumEN;