import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Dave = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio dave__text backdrop__bio">



            <div className="container__picture">
                <picture>
                    <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Dave-Phillips_FLESHTV.webp"
                        type="image/webp" alt="Dave Phillips FLESHTV" />
                    <img className="artist__picture" src="/content/pictures/artists-portrait/Dave-Phillips_FLESHTV.jpg" loading="lazy"
                        alt="Dave Phillips FLESHTV" width="1000" height="1000" />
                </picture>
                <div className="credit__photo">© Randy Yau</div>
            </div>


            <div>
                <p className="text">
                    {t('dave-bio')}
                    <br />

                    <a href="https://davephillips.ch/about" target="_blank">davephillips.ch/</a><br />
                    <a href="https://dave-phillips.bandcamp.com/" target="_blank"> dave-phillips.bandcamp.com/</a><br />

                </p>

                <h1>Dave Phillips (CH)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Dave;