import React from 'react';

import { useTranslation } from 'react-i18next';
import '../js/i18n';

/* import gsap from 'gsap'; */

const ButtonBuy = () => {

    const handleClick = () => {
        const paymentElement = document.querySelector('.payment');
        if (paymentElement) {
            paymentElement.scrollIntoView({ behavior: 'smooth' });
        }

        /*   const paymentElement = document.querySelector('.payment');
          if (paymentElement) {
              const elementRect = paymentElement.getBoundingClientRect();
              const absoluteElementTop = elementRect.top + window.pageYOffset;
              const middle = absoluteElementTop - (window.innerHeight / 2) + (elementRect.height / 2);
  
  
              window.scrollTo({
                  top: middle,
                  behavior: 'smooth'
              });
  
          } */
    };

    const { t } = useTranslation();

    return (

        <div className="navi button__buy" onClick={handleClick}>
            <p>{t('buy-button')}</p>
        </div>

    )
}

export default ButtonBuy;