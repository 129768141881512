import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const RymSasha = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio rym__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Rym+Sascha_FLESHTV.webp"
                    type="image/webp" alt="Rym Nouioua and Sascha Brosamer FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Rym+Sascha_FLESHTV.jpg" loading="lazy"
                    alt="Rym Nouioua and Sascha Brosamer FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('rym-bio')}
                    <br />

                    <a href="https://www.rym-nouioua.com/" target="_blank">rym-nouioua.com/</a><br />
                    <a href="https://grubenwehrfreiburg.bandcamp.com/album/swarming/"
                        target="_blank">grubenwehrfreiburg.bandcamp.com/album/swarming/</a><br /> <br />



                    {t('sasha-bio')}
                    <br />

                    <a href="https://www.saschabrosamer.com/" target="_blank">saschabrosamer.com/</a><br />
                    <a href="https://totalsilencecologne.bandcamp.com/album/the-bat-effect-2/"
                        target="_blank">totalsilencecologne.bandcamp.com/album/the-bat-effect-2/</a><br />
                </p>

                <h1>Rym Nouioua + Sascha Brosamer (DE)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default RymSasha;