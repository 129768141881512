import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Arash = () => {

    const { t } = useTranslation();

    return (




        <div className="artist__bio arash__text backdrop__bio">


            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Arash-Pandi_FLESHTV.webp"
                    type="image/webp" alt="Arash Pandi FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Arash-Pandi_FLESHTV.jpg" loading="lazy"
                    alt="Arash Pandi FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('arash-bio')}
                    <br />

                    <a href="http://arashpandi.com/" target="_blank">arashpandi.com/</a><br />
                    <a href="https://zabtesote.bandcamp.com/album/exotic-paradox/"
                        target="_blank">zabtesote.bandcamp.com/album/exotic-paradox/</a><br />

                </p>

                <h1>Arash Pandi (IR)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Arash;