import React from "react";

import { useTranslation } from 'react-i18next';
import '../js/i18n';

const Credits = () => {

    const { t } = useTranslation();

    return (
        <div className="backdrop credits">
            <p>{t('graphics')} <a href="https://fluoroptimal.com/" target="_blank" rel="noreferrer">Fluor Optimal</a> · website <a
                href="https://ff87c3.eu/" target="_blank" rel="noreferrer">ff87c3.eu</a></p>
        </div>

    )

}

export default Credits;