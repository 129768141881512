import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('en'); // Initial state is 'en'

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLanguage); // This will change the language in i18next
    setLanguage(newLanguage); // Update the state to reflect the new language
    window.speechSynthesis.cancel();
  };

  return (

    /*  <div id="buttons_top_container"> */


    <div id="languageSwitcher" className='button__lang navi'>
      <span onClick={toggleLanguage}>{language === 'en' ? 'fr' : 'en'}</span>
    </div>

    /*  </div> */

  );
};

export default LanguageSwitcher;