import React, { useEffect } from 'react';



const ButtonsSpeechAboutFR = () => {

    useEffect(() => {
        const textAlbumFr = `FLESH TV INVITE A L'EXAMINATION SENSIBLE ET CONCEPTUELLE DE LA QUESTION DU VIVANT À TRAVERS L'ART ET LA RECHERCHE. NOTRE VOLONTÉ EST D'EN FAIRE LE LIEU DE RENCONTRE ET DE FRICTION DE PLUSIEURS DISCIPLINES ET MÉDIUMS, À COMMENCER PAR L'ART SONORE, LA MUSIQUE EXPÉRIMENTALE, ÉLECTRO- ACOUSTIQUE, ÉLECTRONIQUE, BRUITISTE ET AUTRES SOUS-GENRES GRAVITANT AUTOUR DE CETTE SPHÈRE. FLESH TV ACCUEILLERA ÉGALEMENT DES EXPÉRIENCES AUDIO-VISUELLES, DES FORMATS HYBRIDES AINSI QUE DU CONTENU LITTÉRAIRE SOUS FORME D'ESSAIS, D'ARTICLES ET DE TEXTES DE SENSIBILISATION.

        FLESH TV V/A, premier projet inaugurant la plateforme, entend utiliser l'art sonore comme outil politique et medium de conscientisation sur la question de l'exploitation animale, rejoignant ainsi le débat actuel autour des droits des animaux à travers un format créatif et innovant. Cette compilation rassemble plus d'une vingtaine d'artistes à travers le monde dont le point commun est de partager une sensibilité et/ou un engagement vis-à-vis de la cause animale. Chaque participant·e y a contribué en composant une pièce sonore exclusive ainsi qu'en soumettant un texte, témoin de son approche personnelle de la condition animale et des enjeux que celle-ci soulève.
        
        La singularité de cette oeuvre collective repose sur sa capacité à valoriser un champ versatile de recherche sonore, tout en l'associant à la puissance d'un manifeste porté par plusieurs voix. C'est ainsi qu'iels viennent nourrir de leurs parcours et questionnements respectifs une réflexion politique globale ainsi que de nouvelles perspectives sur le rôle de l'artiste dans une quête de justice et de compassion élargie à toutes les espèces.
        
        Tandis que le combat contre la discrimination et l'oppression se caractérise par son inclusivité grandissante — englobant le genre, l’ethnicité, la classe, les orientations sexuelles, le handicap, etc., l'échec de cette dynamique à intégrer l'exploitation animale à son spectre suscite étonnement et consternation. La convergence des luttes (sexisme, racisme, homophobie, etc) tourne le dos au spécisme – incapable de voir les liens qui traversent ces différentes causes. Nos capacités de progression, d'évolution dépendent de cette vision large, seule capable de révéler notre part de violence et de responsabilité au sein de systèmes de domination et de brutalité.
        
        Enfin, ce projet est l'occasion d'apporter notre soutien financier¹ à des associations et à des activistes impliqué.e.s dans les causes que nous défendons. La plupart étant des initiatives locales, spécifiques et peu connues du grand public, il nous paraît important d'offrir une visibilité à leur travail. En invitant chaque artiste à choisir une organisation ou un·e activiste, Flesh TV espère tisser un réseau d'acteur·rice·s et de structures œuvrant pour un futur où toute vie est valorisée et respectée.
        
        Flesh TV supporte toute forme d'activisme, des efforts passant par la voie législative aux approches ouvertement militantes ; son objectif étant de fédérer au-delà des divergences et modes opérationnels de chacun·e. Il s'agit d'agir sur tous les plans, à tous les niveaux pour offrir à nos utopies la force et l'impact nécessaire pour s'incarner. Il est plus que jamais vital de soutenir les projets, combats, mobilisations qui s'érigent telles des lueurs d'espoir face à l'inaction politique et dont les efforts sont à la hauteur du vide institutionnel sur ces questions.
        
        Constituer et développer un réseau d'artistes, de chercheur.euse.s, d'activistes, d'associations ainsi qu'un public autour de Flesh TV est au cœur de ce projet; l'un de nos souhaits les plus chers étant de construire une communauté autour de cette initiative. Si vous souhaitez contribuer d'une manière ou d'une autre à cet élan que nous souhaitons collectif, n'hésitez pas à nous contacter.`




        const playElFr = document.getElementById('play-album-fr');
        const pauseElFr = document.getElementById('pause-album-fr');
        //const stopEl = document.getElementById('stop-fr');

        // add UI event handlers
        playElFr.addEventListener('click', playAboutFr);
        pauseElFr.addEventListener('click', stop);
        //stopEl.addEventListener('click', stop);

        function playAboutFr() {



            const utterance = new SpeechSynthesisUtterance(textAlbumFr);

            utterance.lang = 'fr-FR';

            utterance.addEventListener('start', handleStart);
            utterance.addEventListener('pause', handlePause);
            utterance.addEventListener('resume', handleResume);
            utterance.addEventListener('end', handleEnd);
            utterance.addEventListener('boundary', handleBoundary);
            window.speechSynthesis.speak(utterance);
        }


        function pauseAboutFr() {
            window.speechSynthesis.pause();
        }

        function stop() {
            window.speechSynthesis.cancel();
            handleEnd();
        }
        function handleStart() {
            playElFr.disabled = true;
            pauseElFr.disabled = false;
            //stopEl.disabled = false;
        }

        function handlePause() {
            playElFr.disabled = false;
            pauseElFr.disabled = true;
            //stopEl.disabled = false;
        }

        function handleResume() {
            playElFr.disabled = true;
            pauseElFr.disabled = false;

        }

        function handleEnd() {
            playElFr.disabled = false;
            pauseElFr.disabled = true;

        }

        function handleBoundary(event) {
            if (event.name === 'sentence') {
                // we only care about word boundaries
                return;
            }

            const wordStart = event.charIndex;

            let wordLength = event.charLength;
            if (wordLength === undefined) {
                // Safari doesn't provide charLength, so fall back to a regex to find the current word and its length (probably misses some edge cases, but good enough for this demo)
                const match = textAlbumFr.substring(wordStart).match(/^[a-z\d']*/i);
                wordLength = match[0].length;
            }

            // wrap word in <mark> tag
            const wordEnd = wordStart + wordLength;
            const word = textAlbumFr.substring(wordStart, wordEnd);
            const markedText = textAlbumFr.substring(0, wordStart) + '<mark>' + word + '</mark>' + textAlbumFr.substring(wordEnd);
            //textEl.innerHTML = markedText;
        }



    })

    return (
        <div className="speech-buttons">
            <button id="play-album-fr">lire</button>
            <button id="pause-album-fr" disabled>arreter</button>
        </div>

    )

}

export default ButtonsSpeechAboutFR;