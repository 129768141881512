import React from "react";
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../js/i18n';

/* const handleClickAbout = () => {
   window.scrollTo({
       top: 0,
       behavior: 'smooth'
   });

   //fade out
   gsap.to('.button__fleshtv', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })
   gsap.to('.button__about', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })

   //fade in
   gsap.to('.about__title', { autoAlpha: 1, duration: 2, ease: "power3.inOut" })
   gsap.to('.about__container', { autoAlpha: 1, pointerEvents: "auto", duration: 2, ease: "power3.inOut" })
   gsap.to('.button__back', { autoAlpha: 1, duration: 2, pointerEvents: 'auto', y: -10, delay: 1.5, ease: "power3.inOut" })
   gsap.to('.menu', { pointerEvents: "none" })
   //gsap.to('.main__about', { display: 'block' })

   // pointerevents
   gsap.to('canvas', { pointerEvents: "none" })
};

const handleClickAlbum = () => {
   window.scrollTo({
       top: 0,
       behavior: 'smooth'
   });

   //fade out
   gsap.to('.button__fleshtv', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })
   gsap.to('.button__about', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })

   //fade in
   gsap.to('.compilation__container', { autoAlpha: 1, pointerEvents: "auto", duration: 1, delay: 1, y: -25, ease: "power3.inOut" })
   gsap.to('.compilation__title', { autoAlpha: 1, duration: 2, ease: "power3.inOut" })
   gsap.to('.button__back', { autoAlpha: 1, duration: 2, pointerEvents: 'auto', y: -10, delay: 1.5, ease: "power3.inOut" })
   gsap.to('.button__buy', { autoAlpha: 1, duration: 2, pointerEvents: 'auto', y: -10, delay: 1.5, ease: "power3.inOut" })

   //pointer events
   gsap.to('canvas', { pointerEvents: 'none', duration: 1 })

};  */



const Menu = () => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleClickAbout = () => {

        /*  window.scrollTo({
             top: 0,
             behavior: 'smooth'
         }); */

        //fade out
        gsap.to('.button__fleshtv', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })
        gsap.to('.button__about', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })

        //fade in
        // gsap.to('.about__title', { autoAlpha: 1, duration: 2, ease: "power3.inOut" })
        //gsap.to('.about__container', { autoAlpha: 1, pointerEvents: "auto", duration: 2, ease: "power3.inOut" })
        gsap.to('.button__back', { autoAlpha: 1, duration: 2, pointerEvents: 'auto', y: -10, delay: 1.5, ease: "power3.inOut" })
        //gsap.to('.main__about', { display: 'block' })

        // pointerevents
        gsap.to('canvas', { pointerEvents: "none" })
        //gsap.to('.menu', { pointerEvents: "none" })

        //gsap.to('.webgl', { pointerEvents: "none" })




        setTimeout(() => {
            navigate('/about'); // Navigate to the "about" page
        }, 1000);
    };

    const handleClickAlbum = () => {

        /*  window.scrollTo({
             top: 0,
             behavior: 'smooth'
         }); */

        //fade out
        gsap.to('.button__fleshtv', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })
        gsap.to('.button__about', { autoAlpha: 0, duration: 0.5, ease: "power3.inOut" })

        //fade in
        // gsap.to('.compilation__container', { autoAlpha: 1, pointerEvents: "auto", duration: 1, delay: 1, y: -25, ease: "power3.inOut" })
        // gsap.to('.compilation__title', { autoAlpha: 1, duration: 2, ease: "power3.inOut" })
        gsap.to('.button__back', { autoAlpha: 1, duration: 2, pointerEvents: 'auto', y: -10, delay: 1.5, ease: "power3.inOut" })
        // gsap.to('.button__buy', { autoAlpha: 1, duration: 2, pointerEvents: 'auto', y: -10, delay: 1.5, ease: "power3.inOut" })

        //pointer events
        gsap.to('canvas', { pointerEvents: 'none' })
        // gsap.to('.webgl', { pointerEvents: "none" })


        setTimeout(() => {
            navigate('/album'); // Navigate to the "FleshTV V/A 01" page
        }, 1000);
    };

    return (

        <div>

            <div className="menu">
                <div className="navi button__about"
                    onClick={handleClickAbout} >

                    <p>{t('about-title')}</p>
                </div>
            </div>

            <div className="menu">
                <div className="navi button__fleshtv"
                    onClick={handleClickAlbum} >FleshTV V/A 01</div>
            </div>

        </div>

    )

}

export default Menu;