import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Sukitoa = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio sukitoa__text backdrop__bio">


            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Sukitoa-O-Namau_FLESHTV.webp"
                    type="image/webp" alt="Sukitoa O Namau FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Sukitoa-O-Namau_FLESHTV.jpg" loading="lazy"
                    alt="Sukitoa O Namau FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('sukitoa-bio')}
                    <br />

                    <a href="https://sukitoaonamau.bandcamp.com/" target="_blank">sukitoaonamau.bandcamp.com/</a><br />
                    <a href="https://rec-on.org/sukitoaonamau.html" target="_blank">rec-on.org/sukitoaonamau.html/</a><br />
                </p>

                <h1>Sukitoa O Namau (MA)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Sukitoa;