import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Felicity = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio felicity__text backdrop__bio">


            <div className="container__picture">
                <picture>
                    <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Felicity-Mangan_FLESHTV.webp"
                        type="image/webp" alt="Felicity Mangan FLESHTV" />
                    <img className="artist__picture" src="/content/pictures/artists-portrait/Felicity-Mangan_FLESHTV.jpg" loading="lazy"
                        alt="Felicity Mangan FLESHTV" width="1000" height="1000" />
                </picture>
                <div className="credit__photo">© Udo Siegfriedt</div>
            </div>


            <div>
                <p className="text">
                    {t('felicity-bio')}
                    <br />

                    <a href="https://felicitymangan.org/" target="_blank">felicitymangan.org/</a><br />
                    <a href="https://felicitymangan.bandcamp.com/" target="_blank">felicitymangan.bandcamp.com/</a><br />

                </p>

                <h1>Felicity Mangan (AU)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Felicity;