import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const AGF = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio agf__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/AGF_FLESHTV.webp"
                    type="image/webp" alt="Antye Greie-Ripatti FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/AGF_FLESHTV.jpg" loading="lazy"
                    alt="Antye Greie-Ripatti FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('agf-bio')}
                    <br />

                    <a href="https://antyegreie.com/" target="_blank">antyegreie.com/</a><br />

                </p>

                <h1>AGF (FI)</h1>
            </div>

            <ButtonClose />

        </div>

    )
}

export default AGF;