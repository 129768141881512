import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Neurokill = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio neurokill__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Neurokill_FLESHTV.webp"
                    type="image/webp" alt="Neurokill FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Neurokill_FLESHTV.jpg" loading="lazy"
                    alt="Neurokill FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('neurokill-bio')}
                    <br />

                    <a href="https://soundcloud.com/neurokillkillkill" target="_blank">soundcloud.com/neurokillkillkill/</a><br />
                    <a href="https://neurokill.bandcamp.com/" target="_blank">neurokill.bandcamp.com/</a><br />
                </p>

                <h1>Neurokill (MX)</h1>
            </div>

            <ButtonClose />

        </div>

    )
}

export default Neurokill;