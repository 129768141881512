import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Merzbow = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio merzbow__text backdrop__bio">

            <div className="container__picture">
                <picture>
                    <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Merzbow_FLESHTV.webp"
                        type="image/webp" alt="Merzbow FLESHTV" />
                    <img className="artist__picture" src="/content/pictures/artists-portrait/Merzbow_FLESHTV.jpg" loading="lazy"
                        alt="Merzbow FLESHTV" width="1000" height="1000" />
                </picture>
                <div className="credit__photo">© Jenny Akita</div>
            </div>

            <div>
                <p className="text">
                    {t('merzbow-bio')}
                    <br />

                    <a href="http://merzbow.net/" target="_blank">merzbow.net/</a><br />
                    <a href="https://merzbow.bandcamp.com/" target="_blank">merzbow.bandcamp.com/</a><br />
                </p>

                <h1>Merzbow (JP)</h1>
            </div>

            <ButtonClose />

        </div>

    )
}

export default Merzbow;