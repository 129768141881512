// GrainBackground.js
import React from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { useFrame } from '@react-three/fiber';
import GrainShaderMaterial from './GrainShaderMaterial';
import { extend } from '@react-three/fiber';

import { PlaneGeometry } from 'three';
extend({ PlaneGeometry });

/* import { PlaneBufferGeometry } from 'three';
extend({ PlaneBufferGeometry }); */

const FullScreenQuad = () => {
    const { gl, scene, camera } = useThree();

    useFrame(() => {
        gl.autoClear = false;
        gl.clearDepth();
        gl.render(scene, camera);
    }, 1);

    return (
        <mesh>
            <planeGeometry args={[2, 2]} />
            <GrainShaderMaterial />
        </mesh>
    );
};

const GrainBackground = () => {
    return (
        <Canvas
            style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: -1 }}
            gl={{ preserveDrawingBuffer: true }}
            camera={{ position: [0, 0, 1] }}
        >
            <FullScreenQuad />
        </Canvas>
    );
};

export default GrainBackground;