import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Carl = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio carl__text backdrop__bio">



            <div className="container__picture">
                <picture>
                    <source className="artist__picture"
                        srcSet="/content/pictures/artists-portrait/webp/Carl-Michael-Von-Hausswolff(CMVH)_FLESHTV.webp"
                        type="image/webp" alt="Carl Michael Von Hausswolff FLESHTV" />
                    <img className="artist__picture"
                        src="/content/pictures/artists-portrait/Carl-Michael-Von-Hausswolff(CMVH)_FLESHTV.jpg" loading="lazy"
                        alt="Carl Michael Von Hausswolff FLESHTV" width="1000" height="1000" />
                </picture>
                <div className="credit__photo">© Marietta von Hausswolff von Baumgarten</div>
            </div>

            <div>
                <p className="text">


                    <Trans i18nKey="carl-bio" components={{ br: <br /> }} />

                    <br />

                    <a href="https://cmvonhausswolff.net/" target="_blank" rel="noreferrer">cmvonhausswolff.net/</a><br />

                </p>

                <h1>Carl Michael Von-Hausswolff (SE)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Carl;