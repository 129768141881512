import React, { useRef, useEffect } from 'react';
import { Canvas, useThree, useFrame, extend } from '@react-three/fiber';
import { OrbitControls as OrbitControlsImpl } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { Environment } from '@react-three/drei'
import gsap from "gsap";


// Extend will make OrbitControls available as a JSX element called orbitControls for us to use.
extend({ OrbitControlsImpl });

const Model = () => {
    const { scene, camera } = useThree();
    const debugObject = { envMapIntensity: 1 };
    const modelRef = useRef(); // Reference to the model


    useEffect(() => {
        const gltfLoader = new GLTFLoader();

        gltfLoader.load('/content/models/FleshTV45.glb', (gltf) => {
            const model = gltf.scene;
            model.position.set(0, -2.5, 0);
            model.rotateY(Math.PI);
            scene.add(model);
            modelRef.current = model; // Assign the loaded model to the ref
            updateAllMaterials(scene, debugObject.envMapIntensity);


            // Button back interaction
            document.querySelector('.button__back').addEventListener('click', function () {
                // GSAP animation for model and camera
                gsap.to(model.position, {
                    duration: 2,
                    delay: 1.5,
                    x: 0,
                    y: -2.5,
                    z: 0,
                    ease: "power3.inOut",
                });

                gsap.to(camera.position, {
                    duration: 2,
                    delay: 1.5,
                    x: 2,
                    y: 0,
                    z: -2,
                    ease: "power3.inOut",
                });

            });

            // Button fleshtv interaction
            document.querySelector('.button__fleshtv').addEventListener('click', function () {
                // GSAP animation for model and camera
                gsap.to(model.position, {
                    duration: 2,
                    delay: 1.5,
                    x: 0,
                    y: -2.5,
                    z: 0,
                    ease: "power3.inOut",
                });

                gsap.to(camera.position, {
                    duration: 2,
                    delay: 1.5,
                    x: 0,
                    y: 0,
                    z: -10,
                    ease: "power3.inOut",
                });

            });

            // Button about interaction
            document.querySelector('.button__about').addEventListener('click', function () {
                // GSAP animation for model and camera
                gsap.to(model.position, {
                    duration: 2,
                    delay: 1.5,
                    x: -5,
                    y: -4,
                    z: 0,
                    ease: "power3.inOut",
                });

                gsap.to(camera.position, {
                    duration: 2,
                    delay: 1.5,
                    x: 0,
                    y: 0,
                    z: -4,
                    ease: "power3.inOut",
                });
            });
        });
    }, [scene, debugObject.envMapIntensity]);


    /*   useFrame(() => {
          if (modelRef.current) {
              // Perform the rotation animation
              modelRef.current.rotation.y -= 0.0025; // Adjust rotation speed as needed
          }
      });
   */
    return null;
};

/* const updateAllMaterials = (scene, envMapIntensity) => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.envMapIntensity = envMapIntensity;
            child.material.needsUpdate = true;
            child.castShadow = true;
            child.receiveShadow = true;
            child.material.metalness = 1;
            child.material.roughness = 0;
        }
    });
}; */

const updateAllMaterials = (scene, envMapIntensity) => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            if (child.material.envMapIntensity !== envMapIntensity) {
                child.material.envMapIntensity = envMapIntensity;
                child.material.needsUpdate = true;
            }
            if (!child.castShadow) child.castShadow = true;
            if (!child.receiveShadow) child.receiveShadow = true;
            if (child.material.metalness !== 1) child.material.metalness = 1;
            if (child.material.roughness !== 0) child.material.roughness = 0;
        }
    });
};


// This component will handle the orbit controls.
const OrbitControls = (props) => {
    const { camera, gl } = useThree();
    const orbitRef = useRef();

    useFrame(() => orbitRef.current && orbitRef.current.update());

    return <orbitControlsImpl ref={orbitRef} args={[camera, gl.domElement]} {...props} />;
};

// Main canvas component
const ThreeCanvas = () => {

    return (
        <Canvas style={{ width: '100%', height: '100vh' }}
            className='webgl'
            camera={{ position: [2, 0, -2], fov: 75 }}
        >
            <Environment files="../content/WeAnimalsMedia4277_3Dmodel_low.jpg" />
            <OrbitControls enableDamping />
            <Model />

        </Canvas>
    );
};

export default ThreeCanvas;