import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const German = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio german__text backdrop__bio">


            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/German-Army_FLESHTV.webp"
                    type="image/webp" alt="German Army FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/German-Army_FLESHTV.jpg" loading="lazy"
                    alt="German Army FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('german-bio')}
                    <br />

                    <a href="https://germanarmymusic.bandcamp.com/" target="_blank">germanarmymusic.bandcamp.com/</a><br />

                </p>

                <h1>German Army (US)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default German;