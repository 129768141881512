import React from "react";

import { useTranslation } from 'react-i18next';
import '../js/i18n';

const Donation = () => {

    const { t } = useTranslation();

    return (
        <div className="donation backdrop about__text">

            <p>{t('donation-01')}<br /><br />
                {t('donation-02')}</p>


            {/*    <a href="https://www.paypal.com/donate/?hosted_button_id=ADW4CVHEN5NQQ" target="_blank" rel="noopener noreferrer">
                <img src="/content/paypal/donate-with-paypal_grey.png" className="button__donate" loading="lazy" alt="Donate" />
            </a>
 */}

            <p className="center__text">
                <br />
                <a href="https://www.paypal.com/donate/?hosted_button_id=ADW4CVHEN5NQQ" target="_blank" rel="noopener noreferrer">donate here</a>
            </p>

        </div>
    )
}

export default Donation;