import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Helene = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio helene__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Helene-Vogelsinger_FLESHTV.webp"
                    type="image/webp" alt="Hélène Vogelsinger FLESHTV" />
                <img className="artist__picture" src="/content/pictures/artists-portrait/Helene-Vogelsinger_FLESHTV.jpg"
                    loading="lazy" alt="Hélène Vogelsinger FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('helene-bio')}
                    <br />

                    <a href="https://helene.vogelsinger.fr/" target="_blank">helene.vogelsinger.fr/</a><br />
                    <a href="https://helenevogelsinger.bandcamp.com/" target="_blank">helenevogelsinger.bandcamp.com/</a><br />
                </p>
                <h1>Hélène Vogelsinger (FR)</h1>
            </div>

            <ButtonClose />

        </div>

    )
}

export default Helene;