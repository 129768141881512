import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../js/i18n';
import ButtonClose from '../ButtonClose';

const Robbie = () => {

    const { t } = useTranslation();

    return (

        <div className="artist__bio robbie__text backdrop__bio">

            <picture>
                <source className="artist__picture" srcSet="/content/pictures/artists-portrait/webp/Robbie-Judkins_FLESHTV.webp"
                    type="image/webp" alt="Robbie Judkins FLESHTV" />
                <img className="artist__picture" srcSet="/content/pictures/artists-portrait/Robbie-Judkins_FLESHTV.jpg" loading="lazy"
                    alt="Robbie Judkins FLESHTV" width="1000" height="1000" />
            </picture>

            <div>
                <p className="text">
                    {t('robbie-bio')}
                    <br />

                    <a href="https://cargocollective.com/robbiejudkins" target="_blank">cargocollective.com/robbiejudkins/</a><br />
                    <a href="https://lefthandcutsofftheright.bandcamp.com/"
                        target="_blank">lefthandcutsofftheright.bandcamp.com/</a><br />
                </p>

                <h1>Robbie Judkins (UK)</h1>

            </div>

            <ButtonClose />

        </div>

    )
}

export default Robbie;