import React, { useEffect } from 'react';

import PayPalButton from './PayPalButton'; // Adjust the path as necessary


import { useTranslation, Trans } from 'react-i18next';
import '../js/i18n';


import PaypalPaymentReact from './PaypalPaymentReact';

const Payment = () => {

    /*   const { t } = useTranslation(); */

    /*   useEffect(() => {
          const script = document.createElement('script');
          script.src = "https://www.paypal.com/sdk/js?client-id=BAAv-CgwHDrfGljD-xCIW44zMuUQrVDcjLVm_m8qaIXtol3IR4_LT2l8LuXdNmESUCu-Dy-Vs4KJgjz0b8&components=hosted-buttons&disable-funding=venmo&currency=EUR";
          script.async = true;
          script.onload = () => {
              if (window.paypal) {
                  window.paypal.HostedButtons({
                      hostedButtonId: "ZKNEGQMRLREBA"
                  })
                      .render("#paypal-container-ZKNEGQMRLREBA")
              }
          }
  
          document.body.appendChild(script);
  
      }, []); */

    return (

        <div className="intro backdrop payment">

            <p className="center__text">
                <span className="larger__text font__title">Flesh TV V/A 01</span><br /><br />
            </p>

            <p>
                <Trans i18nKey="payment-01" components={{ br: <br /> }} />
                <br /><br />
            </p>

            <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer"><img className="logo" src="/content/logo/low-res/FleshTV_logo_chrome_250.png" loading="lazy"
                alt="FLESHTV logo" width="500" height="445" /></a>

            <p className="center__text">
                <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer">buy here</a>
                <br />
            </p>

            <p className="center__text text__small">
                <Trans i18nKey="payment-02" components={{
                    br: <br />
                }} />
            </p>

            {/*     <p className="center__text">
                <Trans i18nKey="payment-02" components={{
                    br: <br />,

                }} />

                <br /><br />

                <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer">buy here</a>

            </p> */}

            {/*    <div id="paypal-container-ZKNEGQMRLREBA"></div> */}

        </div>

    )

}

export default Payment;