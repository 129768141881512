import React, { useRef, useEffect } from 'react';
import { Canvas, useThree, useFrame, extend } from '@react-three/fiber';
import { OrbitControls as OrbitControlsImpl } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { Environment } from '@react-three/drei'
import gsap from "gsap";

import { WebGLRenderer } from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { LuminosityShader } from 'three/examples/jsm/shaders/LuminosityShader';
import { BrightnessContrastShader } from 'three/examples/jsm/shaders/BrightnessContrastShader';

// Extend will make OrbitControls available as a JSX element called orbitControls for us to use.
extend({ OrbitControlsImpl });

extend({ EffectComposer, RenderPass, ShaderPass });

const Model = () => {
    /*     const { scene, camera } = useThree();
        const debugObject = { envMapIntensity: 1 };
        const modelRef = useRef(); // Reference to the model */

    const { scene, camera } = useThree();
    const modelRef = useRef();


    useEffect(() => {
        const gltfLoader = new GLTFLoader();

        gltfLoader.load('/content/models/FleshTV45.glb', (gltf) => {
            //gltfLoader.load('/content/models/FleshTV_merged.glb', (gltf) => {

            const model = gltf.scene;
            model.position.set(0, -2.5, 0);
            //model.position.set(-5, -10, 0);

            model.rotateY(Math.PI);
            scene.add(model);
            modelRef.current = model;
            updateAllMaterials(scene, 1); // Assuming default envMapIntensity is 1





            // Button back interaction
            document.querySelector('.button__back').addEventListener('click', function () {
                // GSAP animation for model and camera
                gsap.to(model.position, {
                    duration: 2,
                    delay: 1,
                    x: 0,
                    y: -2.5,
                    z: 0,
                    ease: "power3.inOut",
                });

                gsap.to(camera.position, {
                    duration: 2,
                    delay: 1,
                    x: 2,
                    y: 0,
                    z: -2,
                    ease: "power3.inOut",
                });

            });

            // Button fleshtv interaction
            document.querySelector('.button__fleshtv').addEventListener('click', function () {
                // GSAP animation for model and camera
                gsap.to(model.position, {
                    duration: 2,
                    delay: 1.5,
                    x: 0,
                    y: -2.5,
                    z: 0,
                    ease: "power3.inOut",
                });

                gsap.to(camera.position, {
                    duration: 2,
                    delay: 1,
                    x: 0,
                    y: 0,
                    z: -10,
                    ease: "power3.inOut",
                });

            });

            // Button about interaction
            document.querySelector('.button__about').addEventListener('click', function () {
                // GSAP animation for model and camera
                gsap.to(model.position, {
                    duration: 2,
                    delay: 1,
                    x: -5,
                    y: -4,
                    z: 0,
                    ease: "power3.inOut",
                });

                gsap.to(camera.position, {
                    duration: 2,
                    delay: 1,
                    x: 0,
                    y: 0,
                    z: -4,
                    ease: "power3.inOut",
                });
            });
        });
    }, [camera.position, scene]);
    /* }, [scene]); */

    useFrame(() => {
        if (modelRef.current) {
            // Perform the rotation animation
            modelRef.current.rotation.y -= 0.0025; // Adjust rotation speed as needed
        }
    });

    return null;
};


const updateAllMaterials = (scene, envMapIntensity) => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            if (child.material.envMapIntensity !== envMapIntensity) {
                child.material.envMapIntensity = envMapIntensity;
                child.material.needsUpdate = true;
            }
            if (!child.castShadow) child.castShadow = true;
            if (!child.receiveShadow) child.receiveShadow = true;
            if (child.material.metalness !== 1) child.material.metalness = 1;
            if (child.material.roughness !== 0) child.material.roughness = 0;
        }
    });
};




// This component will handle the orbit controls.
const OrbitControls = (props) => {
    const { camera, gl } = useThree();
    const orbitRef = useRef();

    useFrame(() => orbitRef.current && orbitRef.current.update());

    return <orbitControlsImpl ref={orbitRef} args={[camera, gl.domElement]} {...props} />;
};

/* const GrainShader = {
    uniforms: {
        'tDiffuse': { value: null },
        'amount': { value: 0.05 }, // Adjust the grain intensity
        'time': { value: 0.0 }
    },
    vertexShader: `
        varying vec2 vUv;
        
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float amount;
        uniform float time;
        
        varying vec2 vUv;

        float rand(vec2 co) {
            return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453 + time);
        }

        void main() {
            vec4 color = texture2D(tDiffuse, vUv);
            vec2 uvRandom = vUv;
            uvRandom.y *= rand(vec2(uvRandom.y, time));
            color.rgb += rand(uvRandom) * amount;
            gl_FragColor = color;
        }
        
    `
};
 */


const GrainShader = {
    uniforms: {
        'tDiffuse': { value: null },
        'amount': { value: 0.05 }, // Adjust the grain intensity
        'time': { value: 0.0 }
    },
    vertexShader: `
        varying vec2 vUv;
        
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float amount;
        uniform float time;
        
        varying vec2 vUv;

        float rand(vec2 co) {
            return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453 + time);
        }

        void main() {
            vec4 color = texture2D(tDiffuse, vUv);
            vec2 uvRandom = vUv;
            uvRandom.y *= rand(vec2(uvRandom.y, time));
color.rgb = clamp(color.rgb + rand(uvRandom) * amount, 0.0, 1.0);            gl_FragColor = color;
        }
        
    `
};


// Main canvas component
const ThreeCanvasOptimized = () => {

    const renderer = new WebGLRenderer({
        antialias: true, // Set to false if performance is an issue
        powerPreference: 'high-performance', // Hint to the browser to prioritize performance
    });

    /*    const GrainEffect = () => {
           const { scene, camera, gl } = useThree();
           const composer = useRef();
   
           useEffect(() => {
               const renderPass = new RenderPass(scene, camera);
               const brightnessContrastPass = new ShaderPass(BrightnessContrastShader);
               brightnessContrastPass.uniforms['brightness'].value = 0.5; // Adjust brightness
               brightnessContrastPass.uniforms['contrast'].value = 0.5;  // Adjust contrast
   
               composer.current = new EffectComposer(gl);
               composer.current.addPass(renderPass);
               composer.current.addPass(brightnessContrastPass);
           }, [scene, camera, gl]);
   
           useFrame(() => {
               composer.current.render();
           }, 1);
   
           return null;
       }; */

    const GrainEffect = () => {
        const { scene, camera, gl } = useThree();
        const composer = useRef();
        const time = useRef(0);

        useEffect(() => {
            const renderPass = new RenderPass(scene, camera);
            const grainPass = new ShaderPass(GrainShader);
            grainPass.uniforms['amount'].value = 0.15; // Adjust the grain intensity

            composer.current = new EffectComposer(gl);
            composer.current.addPass(renderPass);
            composer.current.addPass(grainPass);
        }, [scene, camera, gl]);

        useFrame((state, delta) => {
            time.current += delta;
            composer.current.passes[1].uniforms['time'].value = time.current;
            composer.current.render();
        }, 1);

        return null;
    };

    /*  const GrayscaleEffect = () => {
         const { scene, camera, gl } = useThree();
         const composer = React.useRef();
 
         useEffect(() => {
             const renderPass = new RenderPass(scene, camera);
             const grayscalePass = new ShaderPass(LuminosityShader);
 
             composer.current = new EffectComposer(gl);
             composer.current.addPass(renderPass);
             composer.current.addPass(grayscalePass);
         }, [scene, camera, gl]);
 
         useFrame(() => {
             composer.current.render();
         }, 1);
 
         return null;
     }; */

    /*     const Lighting = () => {
            return (
                <>
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[10, 10, 10]} intensity={1.5} />
                </>
            );
        }; */
    /* 
        const BrightnessEffect = () => {
            const { scene, camera, gl } = useThree();
            const composer = React.useRef();
    
            useEffect(() => {
                const renderPass = new RenderPass(scene, camera);
                const brightnessContrastPass = new ShaderPass(BrightnessContrastShader);
                brightnessContrastPass.uniforms['brightness'].value = 0.5; // Adjust brightness
                brightnessContrastPass.uniforms['contrast'].value = 0.5;  // Adjust contrast
    
                composer.current = new EffectComposer(gl);
                composer.current.addPass(renderPass);
                composer.current.addPass(brightnessContrastPass);
            }, [scene, camera, gl]);
    
            useFrame(() => {
                composer.current.render();
            }, 1);
    
            return null;
        }; */

    return (
        /*  <Canvas renderer={renderer} style={{ width: '100%', height: '100vh' }} className='webgl' camera={{ position: [-10, -15, -5], fov: 50 }}> */

        <Canvas renderer={renderer} style={{ width: '100%', height: '100vh' }} className='webgl' camera={{ position: [2, 0, -2], fov: 75 }}>
            <Environment files="../content/WeAnimalsMedia4277_3Dmodel_low.jpg" />
            {/*   <OrbitControls enableDamping />
            <Model />
            <GrainEffect /> */}
            {/*   <BrightnessEffect /> */}


            {/* Render background with grain effect */}

            {/*    <GrainEffect /> */}
            <OrbitControls enableDamping />
            {/* Add background elements here */}




            <Model />


            {/* Render model without grain effect on top of the background */}

        </Canvas>
    );
};
export default ThreeCanvasOptimized;