import React, { useEffect } from 'react';



const ButtonsSpeechAboutEN = () => {

    useEffect(() => {
        const textAboutEn = `FLESH TV IS A PLATFORM DEVOTED TO RECHANNELING OUR RELATIONSHIP WITH LIFE ON EARTH TOWARDS A BALANCED AND SUSTAINABLE ONE. WE AIM TO EXPLORE ALTERNATIVE PARADIGMS AND FORMS OF ACTIVISM USING ART, RESEARCH, AND CRITICAL THINKING.

        Rooted in philosophical, ethical, political, economic, and socio-cultural concerns, Flesh TV sprang from perspectives about the concepts of humanity and animality. Anthropogenesis (the study of our origin and development as humans) along with disciplines like biology, anthropology, psychology, and philosophy have shaped our connection to the living world through commonly accepted delineations. Through creative practices and thoughtful debates on societal issues (economy, food, energy, health, etc.), Flesh TV looks at the origins and consequences of the separation between humans and animals from various angles.
        
        The dreadful consequences of overexploitation of animals and nature (species extinction, loss of natural habitat, resource depletion, pollution, waste, climate change, zoonoses, etc.) must make us stop to think about the state of our relationship with the planet and all life on it. This in turn should lead us to consider questioning the division between humans and animals, as the starting point.
        
        Humans defined as 'political animals', gifted with the power of moral reasoning, have worked actively towards their mastery over nature rather than embracing their role within the circle of living things by co-existing with and relying on one another. Human activity has reduced the biomass of wild marine and terrestrial mammals six-fold, and the biomass of plant matter by half.¹Today, humans and intensively farmed livestock account for 96% of mammal biomass: humans representing 36%, domesticated animals (mostly cows and pigs) accounting for 60%, and wild mammals a mere 4%². Simply put, two-thirds of the mammal biomass is made up of life that is bred solely to be consumed.
        
        Flesh TV intends to bring together artists, thinkers, writers, activists, and organizations from all around the world who are interested in tackling these topics. It also aims at highlighting earth & animal rights collectives as well as activists, bringing exposure and support to their work and actions. Its main mission is to channel the power behind a growing community of creators and actors willing to challenge anthropocentrism, and to engage in a cultural transformation of our societies. This change requires a profound reconfiguration of our thinking and sensitivity in order to abandon our inorganic ways (exploitative, mechanistic, destructive) and to shift to a holistic vision of our systems. This platform aspires to be a space for reflection, creation, and action, where like-minded people are allowed to blend their practices with their convictions, connect, collaborate, inspire, and get inspired.
        
        When did human animals dissociate themselves from non-human animals and reduce other lives to objects for the use of their own species? When did humanity and nature become two separate things? Is this separation the root that justifies the structural violence we use against other species and our environment? Did mankind lose a part of its human nature by disconnecting itself as a part of the natural ecosystem?
        
        We began domesticating the entire globe with agriculture. As man became lord and master of animals, domestication imposed a hierarchy onto the interspecies relationship. With the onset of the industrial revolution, the relationship then projected onto the whole of nature, which became in its entirety a mission of domination and control. Agriculture established our concept of the earth as a resource or an asset, mainly based on its ability to produce, without considering its limitations. As we lost our ability to commune with the land, wild, and domesticated life, we also lost our capacity to relate to the living world. As soon as we started defining it solely by its economic value, we were led to the denial of its integrity and sentience.
        
        By turning ecosystems and living beings into 'natural capital,' exploitation and extractivism became the principles of a nonreciprocal, unbalanced, dominance-based relationship, preventing any kind of sustainability. It's clear, our economic models are hindering regeneration and resilience by launching an offensive against life itself, to the point where our societies reject the idea of any cooperation. Despite this, each of us can choose to minimize our negative impact; our way of life is both the problem and the solution.
        
        Flesh TV endeavors to merge art, research, and activism to encourage a political commitment to animal and nature-related topics within these realms. Anyone joining the platform and its various projects is attached, one way or another, on a personal and/or public level, to the common vision, ideals, and hopes underlying this initiative. This can range from participants that simply choose a lifestyle that stands against animal exploitation and nature destruction, to those who use their platforms to raise awareness on these issues, to finally those who create entire projects to reinvent our connection to life on earth. As an art platform, sound label, research medium, transdisciplinary political space, we want to create, host, and nourish ideas, representations, attitudes, and behaviors without which the transition to the future we're envisioning and attempting to incarnate - through our choices and practices - cannot happen.
        
        The rise of agrarian civilizations is the foundation upon which the social and cultural justification of human superiority was built. Dominion over the natural world is grounded in various fields including religion, science, technology, and philosophy. It is influenced by all the systems, disciplines, and models involved in shaping our actions, habits, beliefs, and ways of thinking. Whether we focus on the state of the planet or our treatment of animals, the limits of such a paradigm cannot be ignored when confronted with its disastrous consequences. Is it still justifiable for humans to consider themselves the apex of all life and to prioritize their own needs and interests above those of other species? Is such a hierarchy relevant when we go as far as endangering our own kind?
        
        Setting aside the discussion about the role of reasoning and consciousness in separating humans from animals, it is worth mentioning that the convention on which human supremacy was built has never been considered from an animal-oriented viewpoint. What we call Animal Science today is commonly described as "studying the biology of animals that are under the control of humankind," a discipline that is concerned only with improving the business of producing domestic livestock species.
        
        Even the term 'animal welfare' suffers from an anthropocentric perspective as it relies on a utilitarian attitude towards the well-being of non-human animals: we acknowledge that animals deserve moral consideration and should be treated respectfully or humanely, but we consider it acceptable to harm them for the purpose of human consumption. Recently, as early as the 1900s, disciplines like ethology (the study of animal behavior) appeared and were characterized by versatile and ambiguous applications. They can be used in animal ethics to defend animal autonomy and dignity, yet paradoxically, they can also be implemented by the animal agriculture industry to progress in their standards and further their goals.
        
        Can new paradigms emerge from a thorough reassessment between the concepts of humanity and animality? Can new behaviors appear as a result of adjusting our place on earth? Could they give birth to new relationships, based on sharing and cooperating rather than exploiting and pillaging?
        
        Flesh TV wants to participate in a global effort to shape a new consciousness, one in which co-existence triumphs over domination and humility over arrogance, finally honoring nature's complexity. That same effort intends to help others recognize these subtle interdependencies and how we, as a species, are entangled in sprawling ecosystems. Flesh TV focuses on how we treat non-humans and nature as a starting point to question our condition and relationship to the world; to reflect on the experience of life and our so-called 'humanity,' what it means, and what we want it to mean.
        
        To put it in the most anthropocentric way:
        
        When does one start becoming a human - or stop being one?`


        const playEl = document.getElementById('play-about-en');
        const pauseEl = document.getElementById('pause-about-en');
        //const stopEl = document.getElementById('stop');

        // add UI event handlers
        playEl.addEventListener('click', playAboutEn);
        pauseEl.addEventListener('click', pauseAboutEn);
        //stopEl.addEventListener('click', stop);




        function playAboutEn() {
            if (speechSynthesis.speaking) {
                // there's an unfinished utterance
                speechSynthesis.resume();
            } else {
                // start new utterance
                const utterance = new SpeechSynthesisUtterance(textAboutEn);

                // doesnt pause between two sentences
                //const utterance = new SpeechSynthesisUtterance(document.querySelector('.intro .english').textContent);
                // utterance.lang = 'en-GB'

                /*      utterance.rate = 0.9;
                     utterance.pitch = 0.9; */

                utterance.lang = 'en-GB';
                /* 
                        if (navigator.userAgent.indexOf("Chrome") !== -1) {
                            utterance.voice = voices[33];
                        } else if (navigator.userAgent.indexOf("Safari") !== -1) {
                            utterance.voice = voices[28];
                        } else {
                            utterance.voice = voices[33];
                        } */

                utterance.addEventListener('start', handleStart);
                utterance.addEventListener('pause', handlePause);
                utterance.addEventListener('resume', handleResume);
                utterance.addEventListener('end', handleEnd);
                utterance.addEventListener('boundary', handleBoundary);

                speechSynthesis.speak(utterance);
            }
        }

        function pauseAboutEn() {
            if (speechSynthesis.speaking) {
                speechSynthesis.pause();
            }
        }



        function handleStart() {
            playEl.disabled = true;
            pauseEl.disabled = false;
            // stopEl.disabled = false;
        }

        function handlePause() {
            playEl.disabled = false;
            pauseEl.disabled = true;
            // stopEl.disabled = false;
        }

        function handleResume() {
            playEl.disabled = true;
            pauseEl.disabled = false;
            //  stopEl.disabled = false;
        }

        function handleEnd() {
            playEl.disabled = false;
            pauseEl.disabled = true;
            // stopEl.disabled = true;

            // reset text to remove mark
            //word following

            // textEl.innerHTML = text;
        }

        function handleBoundary(event) {
            if (event.name === 'sentence') {
                // we only care about word boundaries
                return;
            }

            const wordStart = event.charIndex;

            let wordLength = event.charLength;
            if (wordLength === undefined) {
                // Safari doesn't provide charLength, so fall back to a regex to find the current word and its length (probably misses some edge cases, but good enough for this demo)
                const match = textAboutEn.substring(wordStart).match(/^[a-z\d']*/i);
                wordLength = match[0].length;
            }

            // wrap word in <mark> tag
            const wordEnd = wordStart + wordLength;
            const word = textAboutEn.substring(wordStart, wordEnd);
            const markedText = textAboutEn.substring(0, wordStart) + '<mark>' + word + '</mark>' + textAboutEn.substring(wordEnd);
            //textEl.innerHTML = markedText;
        }


    })

    return (
        <div className="speech-buttons ">
            <button id="play-about-en" >read</button>
            <button id="pause-about-en" disabled>stop</button>
        </div>

    )

}

export default ButtonsSpeechAboutEN;