import React from 'react';

import { useTranslation } from 'react-i18next';
import '../js/i18n';

import gsap from 'gsap';

const ButtonClose = () => {

    const handleClick = () => {

        //fade out

        gsap.to('.artist__bio', { autoAlpha: 0, duration: 1, zIndex: 1, ease: "power3.inOut" })
        gsap.to('.button__close', { autoAlpha: 0, duration: 1, zIndex: 1, ease: "power3.inOut" })

        // fade in

        gsap.to('.button__back', { autoAlpha: 1, duration: 1, ease: "power3.inOut" });
        gsap.to('.button__buy', { autoAlpha: 1, duration: 1, ease: "power3.inOut" });
        gsap.to('.compilation__title', { autoAlpha: 1, duration: 1, ease: "power3.inOut" })



        // gsap.to('#buttons_top_container', { autoAlpha: 1, duration: 1, ease: "power3.inOut" });

        // other

        /*       gsap.to('.compilation__container', { filter: "none" })*/
        gsap.to('body, html', { overflowY: "scroll" })

    };


    const { t } = useTranslation();

    return (

        <div className="navi button__close" onClick={handleClick}>
            <p>close</p>
        </div>


    )
}

export default ButtonClose;